import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { sendVerificationEmail } from "../../redux/actions/authAction";
import { useNavigate } from "react-router-dom";

const EmailSend = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSendEmail = async () => {
    try {
      await dispatch(sendVerificationEmail(email));
      navigate("/emailverify");
    } catch (err) {
      console.log("Error Sending the Email ", err);
    }
  };

  return (
    <div className="container">
      <div className="pschanges">
        <h3
          className="text-uppercase text-center mb-4"
          style={{ color: "rgb(74, 144, 226)" }}
        >
          ARTalk
        </h3>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="btn btn-primary w-100"
          onClick={handleSendEmail}
          disabled={email ? false : true}
        >
          Send Email
        </button>
      </div>
    </div>
  );
};

export default EmailSend;
